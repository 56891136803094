import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { useAuthContext } from '../context/AuthContext'
import { Loading } from '../components'

import { getAmbassador, getUrlParamsWithSlug } from '../utils/enrollmentHelpers'

const SlugPage = ({ params }) => {
  const { handleSetUserState } = useAuthContext()
  const location = useLocation()
  const slugArray = location.pathname.split('/')
  const slug = slugArray[1]

  useEffect(() => {
    const getEnroller = async () => {
      let enrollerData = {
        store: slug,
        ambassadorName: await getAmbassador(slug),
      }
      if (!enrollerData.ambassadorName) {
        alert(`Invalid store name: ${slug}`)
        navigate('/')
      } else {
        // CLEAR EXISTING ENROLLER DATA IN LOCAL STORAGE IF ANY
        localStorage.removeItem('enrollerData')
        localStorage.removeItem('persistEnrollerData')
        // SET NEW/UPDATED ENROLLER DATA IN LOCAL STORAGE
        localStorage.setItem('enrollerData', JSON.stringify(enrollerData))
        // SET so know where to go back to
        localStorage.setItem('fromProductLink', 'true')

        handleSetUserState({
          isReferral: true,
          referralData: enrollerData,
          shouldAddPcFee: enrollerData.type === 'pc',
        })
        const path = [''].concat(slugArray.slice(2)).join('/')
        window.location.href = `${location.origin}${path}`
      }
    }

    getEnroller()
  }, [])

  return (
    <>
      <Loading loading={true} message={`Loading store ${slug}...`} />
    </>
  )
}

export default SlugPage
